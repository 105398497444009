import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() {}

  getSpinnerListener() {
    return this.showSpinner$.asObservable();
  }

  getSpinnerStatus(): boolean {
    return this.showSpinner$.getValue();
  }

  showSpinner() {
    this.showSpinner$.next(true);
  }

  hideSpinner() {
    this.showSpinner$.next(false);
  }
}
